/*
 * @description  : 
 * @Author       : Junfeng Fu
 * @Date         : 2022-11-25 16:32:50
 * @LastEditors  : Junfeng Fu
 * @LastEditTime : 2023-03-07 17:11:48
 */
import React, {Component} from 'react'
import './index.scss'
import Body from '../components/body'
import { inject, observer } from 'mobx-react';
import { Modal, Button } from 'antd';
import {addTbStudyLog} from '@/js/commonFn'
import examinationApi from '@/AjaxApi/examinationApi';
@inject('Store','Examination','ExaminationStore')
@observer
class componentName extends Component {
    constructor(props){
        super(props)
        this.state={
            setTime:0,
            Prompt:false,
            outTime:'',//挂机时间(s)
            time: 0,
            intervalId:null,
            onHookInterval:null,
            isExamType: !(window.getQueryString('ExamType') == 'undefined'), //是否是考试

        }
    }
    componentDidMount(){
        window.addEventListener('mousemove',this.mousemove) 
        window.addEventListener('click',this.click)
        this.setOutTime()
    }

    setOutTime = async () => {
        const dateRes=await examinationApi.getSevDate();
        console.log('INIT TIME',Date.parse(dateRes.data))
        sessionStorage.setItem("newChapterStartTime",Date.parse(dateRes.data))
        let res = await examinationApi.getStopTimeConfig()
        this.setState({outTime: res.data * 60},()=>{
            
            // this.setHookUp()
            this.startStudy()
        })
    }

    componentWillUnmount(){
        window.removeEventListener('mousemove',this.mousemove)
        window.removeEventListener('click',this.click)

    }
    mousemove = ()=>{
        this.setState({setTime:0,time:0})
    }
    click = ()=>{
        // let time = new Date();
        // time = time.getTime() / 1000;
        //    console.log(time);
        // sessionStorage.setItem('stopStydyTime',time); // 停止学习时间
        this.setState({setTime:0,time:0})
    }
    //计时器
    startStudy(){
        let ExamType = window.getQueryString('ExamType')
        if(ExamType == 'undefined'){
            this.state.intervalId = setInterval(async () => {
                console.log(this.state.time,this.state.outTime);
                this.setState({time:this.state.time+1})
                if(this.state.time>this.state.outTime){
                    clearInterval(this.state.intervalId);
                    this.setState({setTime:0,Prompt:true,time:0})
                    await addTbStudyLog(this.props)
                }
            }, 1000)
        }        
    }
    // setHookUp(){
    //     let ExamType = window.getQueryString('ExamType')
    //     if(ExamType == 'undefined'){
    //         this.state.onHookInterval = setInterval(async ()=>{
    //             console.log('setHookUp');
    //             let time = new Date();
    //             time = time.getTime() / 1000; 
    //             let oldTime = sessionStorage.getItem('stopStydyTime');
    //             oldTime = parseInt(oldTime);
    //                 let rTime = (time - oldTime) -  this.state.outTime;
    //                 if(rTime>0){ // 超出8 分钟
    //                     sessionStorage.setItem("chapterSuspendTime",(oldTime +  this.state.outTime) * 1000)
    //                     clearInterval(this.state.onHookInterval)
    //                     this.setState({setTime:0,Prompt:true})
    //                     await addTbStudyLog(this.props)
    //                     return false;
    //                 }
    //         },1000)
    //     }
    // }
     //继续学习
     async continueStudy(){
        this.setState({Prompt:false})
        const dateRes=await examinationApi.getSevDate();
        sessionStorage.setItem("newChapterStartTime",Date.parse(dateRes.data))
        sessionStorage.setItem("chapterSuspendTimeEnd",Date.parse(new Date()))
        // this.setHookUp()
        this.startStudy()
        // this.$refs.video && this.$refs.video.play()
    }
    handleOkGJ = async ()=>{
        if(!this.state.Prompt)
            await addTbStudyLog(this.props)
        let ipcRenderer = window.require("electron").ipcRenderer
        if(ipcRenderer){
            localStorage.clear()
            sessionStorage.clear()
            ipcRenderer.send("exit");
        }
    }
    handleCancelGJ = ()=>{
        this.continueStudy()
    }
    okText = () => (
        <p>Continue</p>
    )
    cancelText = () => (
        <p>Quit</p>
    )
    render() {
        let {isExamType} = this.state
        return (
            <div className='Main_box'>
                <Modal title="Hint"  centered visible={this.state.Prompt} onOk={this.handleCancelGJ } onCancel={this.handleOkGJ} zIndex={99999} okText={this.okText()} cancelText={this.cancelText()}>
                    <p>You have note studied for a long time. The study has been suspended.</p>
                 
                </Modal>
                <div className='Main_head'>
                    {
                        isExamType ? 
                        <h1>AIR TRAFFIC CONTROLLER ENGLISH TEST SYSTEM</h1>
                        :
                        <h1>AIR TRAFFIC CONTROLLER ENGLISH SIM-TEST SYSTEM</h1>
                    }
                    
                </div>
                <Body />
            </div>
        )
    }
}

export default componentName
