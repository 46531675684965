import React from 'react';
import ReactDOM from 'react-dom';

// require('dotenv').config();

import { HashRouter } from 'react-router-dom'
import Routers from './views'

import './js/common'
import './styles/style.less'
import './styles/common.css'
import './styles/style.scss'


class App extends React.Component {
    componentDidMount(){
    }
    componentWillUnmount(){
    }
    onConnectionChange = (type)=>{ // 网络状态 监听
    }

    ExamError = () => { 
    }
    
    render() {
        return (
            <HashRouter>
                <Routers />
            </HashRouter>
        )
    }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
