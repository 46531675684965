

const enterpriseId='nwatmb';
const APIS={
    dev:{
        BaseUrl:'http://192.168.1.108:5007',
        fileUrl:'https://avie.ltd:15544/',
        uploadUrl:'https://avie.ltd:15012/postFile/?dir=/record',
        webSocketUrl:'ws://192.168.1.110:5001',
        uploadUrl_n:'https://avie.ltd:15012',        
    },
    pre:{
        // BaseUrl: 'https://front.gateway.nwatmb.icao.caetp.cn',
        // AudioUrl:"https://resource.nwatmb.icao.caetp.cn",
        // fileUrl: 'https://resource.nwatmb.icao.caetp.cn',
        // uploadUrl: 'https://uploadfiles.nwatmb.icao.caetp.cn/postFile/?dir=/record',
        // webSocketUrl: 'ws://192.168.1.220:15001',
        // uploadUrl_n:'https://uploadfiles.nwatmb.icao.caetp.cn',
        // ImgUrl: 'https://resource.nwatmb.icao.caetp.cn/Images/',//图片回显        
        BaseUrl: 'https://avie.ltd:15013',
        AudioUrl:"https://avie.ltd:15544/",
        fileUrl: 'https://avie.ltd:15544/',
        uploadUrl: 'https://avie.ltd:15012/postFile/?dir=/record',
        webSocketUrl: 'ws://192.168.1.220:15001',
        uploadUrl_n:'https://avie.ltd:15012',
        ImgUrl: 'https://avie.ltd:15544/Images/',//图片回显    
    },
    pro:{
        //中南
        csatmb:{
            BaseUrl: 'https://front.gateway.csatmb.com',
            AudioUrl:"https://resource.csatmb.com",
            fileUrl: 'https://resource.csatmb.com',
            uploadUrl: 'https://uploadfiles.csatmb.com/postFile/?dir=/record',
            webSocketUrl: 'ws://192.168.1.220:15001',
            uploadUrl_n:'https://uploadfiles.csatmb.com',
            ImgUrl: 'https://resource.csatmb.com/Images/',//图片回显
        },
        //三亚
        syatmb:{
            BaseUrl: 'https://front.gateway.icao.syatmb.com',
            AudioUrl:"https://resource.icao.syatmb.com",
            fileUrl: 'https://resource.icao.syatmb.com',
            uploadUrl: 'https://uploadfiles.icao.syatmb.com/postFile/?dir=/record',
            webSocketUrl: 'ws://192.168.1.220:15001',
            uploadUrl_n:'https://uploadfiles.icao.syatmb.com',
            ImgUrl: 'https://resource.icao.syatmb.com/Images/',//图片回显
        },
        //西北
        nwatmb:{
             BaseUrl: 'https://front.gateway.nwatmb.cn',
             AudioUrl:"https://resource.nwatmb.cn",
             fileUrl: 'https://resource.nwatmb.cn',
             uploadUrl: 'https://uploadfiles.nwatmb.cn/postFile/?dir=/record',
             webSocketUrl: 'ws://192.168.1.220:15001',
             uploadUrl_n:'https://uploadfiles.nwatmb.cn',
             ImgUrl: 'https://resource.nwatmb.cn/Images/',//图片回显
        }
    }
}
const OPIAPI={
    //    apiServer: 'api.51vmr.cn', // opi 面试服务器地址
    // apiServer:'aetsnew.cafuc.edu.cn:8143',
    apiServer:'opi.caeen.cn:8143',
    //   apiServer:'line2.51vmr.cn', 
    apiId:'63cdfb8a-03ef-44ae-8da6-d329f505deb8', 
    apiToken:'07157124-7044-4009-85fd-36dc6b1ef317', 
    auth:'800',
    fileSave:'https://pre.examination.caeen.cn:20086/tfsFileSystem/filesDownloadSave?fileName=',
    fileTwo:'http://pre.audio.caeen.cn:18010/',
    // eyeAuthorize:"192.168.1.110$&8273$&CB3ZWEDWQHVHKEML$&2",
    eyeAuthorize:"aetsnew.cafuc.edu.cn$&8273$&6IIIF4QTAHVHLBXB$&2",
}
const env = process.env.REACT_APP_ENV || 'dev'
const URL = (env === 'pro') ? APIS[env][enterpriseId] : APIS[env]
export default ({
    ...URL,
    ...OPIAPI
});

/**
 * 
 * 
 * 以下是老配置，已废弃
 * 
 * 
 * 
 */
// const urls = {
//     test() {
//         const AllUrl = {  // test1 110 test2 111  PreReleaseIn 预发布内网 PreReleaseOut 预发布 外网  FormalIn 正式内网 FormalOut 正式外网
//             test1:{
//                 BaseUrl:'http://192.168.1.110:5001',
//                 fileUrl:'http://192.168.1.110:5004/filesDownload?filePath=',
//                 uploadUrl:'http://125.64.74.28:18100/tfsFileSystem/uploadFile',
//                 webSocketUrl:'ws://192.168.1.110:5001',
//                 uploadUrl_n:'https://192.168.1.110:15004',        
//             },
//             test2:{
                // 预发布
                // BaseUrl: 'https://front.gateway.nwatmb.icao.caetp.cn',
                // AudioUrl:"https://resource.nwatmb.icao.caetp.cn",
                // fileUrl: 'https://resource.nwatmb.icao.caetp.cn',
                // uploadUrl: 'https://uploadfiles.nwatmb.icao.caetp.cn/postFile/?dir=/record',
                // webSocketUrl: 'ws://192.168.1.220:15001',
                // uploadUrl_n:'https://uploadfiles.nwatmb.icao.caetp.cn',
                // ImgUrl: 'https://resource.nwatmb.icao.caetp.cn/Images/',//图片回显

                //本地
                // BaseUrl: 'http://192.168.1.108:5007',
                // AudioUrl:"http://192.168.1.220:9026",
                // fileUrl: 'http://192.168.1.220:9026',
                // uploadUrl: 'http://192.168.1.220:10004/postFile/?dir=/record',
                // webSocketUrl: 'ws://192.168.1.220:15001',
                // uploadUrl_n:'https://192.168.1.102:5004',
                // ImgUrl: 'http://192.168.1.220:9026/Images/',//图片回显

                //西南/首都机场
                // BaseUrl: 'https://front.gateway.icao.caetp.cn',
                // AudioUrl:"https://resource.icao.caetp.cn", 
                // fileUrl: 'https://resource.icao.caetp.cn',
                // uploadUrl: 'https://uploadfiles.icao.caetp.cn/postFile/?dir=/record',
                // webSocketUrl: 'ws://192.168.1.220:15001',
                // uploadUrl_n:'https://uploadfiles.icao.caetp.cn',
                // ImgUrl: 'https://resource.icao.caetp.cn/Images/',//图片回显

                //中南
                // BaseUrl: 'https://front.gateway.csatmb.com',
                // AudioUrl:"https://resource.csatmb.com",
                // fileUrl: 'https://resource.csatmb.com',
                // uploadUrl: 'https://uploadfiles.csatmb.com/postFile/?dir=/record',
                // webSocketUrl: 'ws://192.168.1.220:15001',
                // uploadUrl_n:'https://uploadfiles.csatmb.com',
                // ImgUrl: 'https://resource.csatmb.com/Images/',//图片回显

                //北京空管局
                // BaseUrl: 'https://front.gateway.icao.caetn.cn',
                // AudioUrl:"https://resource.icao.caetn.cn",
                // fileUrl: 'https://resource.icao.caetn.cn',
                // uploadUrl: 'https://uploadfiles.icao.caetn.cn/postFile/?dir=/record',
                // webSocketUrl: 'ws://192.168.1.220:15001',
                // uploadUrl_n:'https://uploadfiles.icao.caetn.cn',
                // ImgUrl: 'https://resource.icao.caetn.cn/Images/',//图片回显

                 // 西北
                //  BaseUrl: 'https://front.gateway.nwatmb.cn',
                //  AudioUrl:"https://resource.nwatmb.cn",
                //  fileUrl: 'https://resource.nwatmb.cn',
                //  uploadUrl: 'https://uploadfiles.nwatmb.cn/postFile/?dir=/record',
                //  webSocketUrl: 'ws://192.168.1.220:15001',
                //  uploadUrl_n:'https://uploadfiles.nwatmb.cn',
                //  ImgUrl: 'https://resource.nwatmb.cn/Images/',//图片回显

                // 三亚
    //             BaseUrl: 'https://front.gateway.icao.syatmb.com',
    //             AudioUrl:"https://resource.icao.syatmb.com",
    //             fileUrl: 'https://resource.icao.syatmb.com',
    //             uploadUrl: 'https://uploadfiles.icao.syatmb.com/postFile/?dir=/record',
    //             webSocketUrl: 'ws://192.168.1.220:15001',
    //             uploadUrl_n:'https://uploadfiles.icao.syatmb.com',
    //             ImgUrl: 'https://resource.icao.syatmb.com/Images/',//图片回显


    //         },
    //         PreReleaseIn:{
    //             BaseUrl: 'https://pre.gateway.vpn.caeen.cn',
    //             fileUrl: 'https://pre.examination.caeen.cn:20086/tfsFileSystem/filesDownload?fileName=',
    //             uploadUrl: 'https://pre.examination.caeen.cn:20086/tfsFileSystem/uploadFile',
    //             webSocketUrl: 'ws://pre.gateway.vpn.caeen.cn',
    //             uploadUrl_n:'https://pre.fileupload.vpn.caeen.cn',
    //         },
    //         PreReleaseOut:{
    //             BaseUrl: 'https://pre.gateway.caeen.cn:2443',
    //             fileUrl: 'https://pre.examination.caeen.cn:20086/tfsFileSystem/filesDownload?fileName=',
    //             uploadUrl: 'https://pre.examination.caeen.cn:20086/tfsFileSystem/uploadFile',
    //             webSocketUrl: 'ws://pre.gateway.caeen.cn:2443',
    //             uploadUrl_n:'https://pre.fileupload.caeen.cn:2443',
    //         },
    //         FormalIn:{
    //             BaseUrl: 'https://gateway.vpn.caeen.cn',
    //             fileUrl: 'https://tfsupload.vpn.caeen.cn/tfsFileSystem/filesDownload?fileName=',
    //             uploadUrl: 'https://tfsupload.vpn.caeen.cn/tfsFileSystem/uploadFile',
    //             webSocketUrl: 'ws://gateway.vpn.caeen.cn',
    //             uploadUrl_n:'https://fileupload.vpn.caeen.cn',
    //         },
    //         FormalOut:{
    //             BaseUrl: 'https://gateway.vpn.caeen.cn:1443',
    //             fileUrl: 'https://tfsupload.vpn.caeen.cn:1443/tfsFileSystem/filesDownload?fileName=',
    //             uploadUrl: 'https://tfsupload.vpn.caeen.cn:1443/tfsFileSystem/uploadFile',
    //             webSocketUrl: 'ws://gateway.vpn.caeen.cn:1443',
    //             uploadUrl_n:'https://fileupload.vpn.caeen.cn:1443',
    //         },
    //     }
        
    //     function GetQueryString(name){
    //         var url = window.location.href.split('?')[1];
    //         let localType = new URLSearchParams(url).get(name);
    //         return localType;
    //     }
    //     let current = {}
    //     let local = '';
    //     if(!!GetQueryString('localType')){
    //         local = GetQueryString('localType');
    //         sessionStorage.setItem('localType',local);
    //     }else{
    //         local = sessionStorage.getItem('localType');
    //     }
        
    //     if(!!!local) local = 'test2';
    //     current = {
    //         ...AllUrl[local],
    //     //    apiServer: 'api.51vmr.cn', // opi 面试服务器地址
    //         // apiServer:'aetsnew.cafuc.edu.cn:8143',
    //         apiServer:'opi.caeen.cn:8143',
    //     //   apiServer:'line2.51vmr.cn', 
    //         apiId:'63cdfb8a-03ef-44ae-8da6-d329f505deb8', 
    //         apiToken:'07157124-7044-4009-85fd-36dc6b1ef317', 
    //         auth:'800',
    //         fileSave:'https://pre.examination.caeen.cn:20086/tfsFileSystem/filesDownloadSave?fileName=',
    //         fileTwo:'http://pre.audio.caeen.cn:18010/',
    //     // eyeAuthorize:"192.168.1.110$&8273$&CB3ZWEDWQHVHKEML$&2",
	// 	    eyeAuthorize:"aetsnew.cafuc.edu.cn$&8273$&6IIIF4QTAHVHLBXB$&2",
    //     }
        
    //     return current;        
    // },
//     pre() {
//         const AllUrl = {  // test1 110 test2 111  PreReleaseIn 预发布内网 PreReleaseOut 预发布 外网  FormalIn 正式内网 FormalOut 正式外网
//             PreReleaseOut:{
//                 BaseUrl: 'https://front.gateway.nwatmb.icao.caetp.cn',
//                 AudioUrl:"https://resource.nwatmb.icao.caetp.cn",
//                 fileUrl: 'https://resource.nwatmb.icao.caetp.cn',
//                 uploadUrl: 'https://uploadfiles.nwatmb.icao.caetp.cn/postFile/?dir=/record',
//                 webSocketUrl: 'ws://192.168.1.220:15001',
//                 uploadUrl_n:'https://uploadfiles.nwatmb.icao.caetp.cn',

//                 ImgUrl: 'https://resource.nwatmb.icao.caetp.cn/Images/',//图片回显

//                 // BaseUrl: 'https://pre.examination.caeen.cn:20084',
//                 // fileUrl: 'https://pre.examination.caeen.cn:20086/tfsFileSystem/filesDownload?fileName=',
//                 // uploadUrl: 'https://pre.examination.caeen.cn:20086/tfsFileSystem/uploadFile',
//                 // webSocketUrl: 'ws://pre.examination.caeen.cn:20084',
//                 // uploadUrl_n:'https://pre.examination.caeen.cn:20085',
//             },
//            }
        
//         function GetQueryString(name){
//             var url = window.location.href.split('?')[1];
//             let localType = new URLSearchParams(url).get(name);
//             return localType;
//         }
//         console.log(window.location.href,123123123);
//         let current = {}
//         let local = '';
//         if(!!GetQueryString('localType')){
//             local = GetQueryString('localType');
//             sessionStorage.setItem('localType',local);
//         }else{
//             local = sessionStorage.getItem('localType');
//         }
//         console.log(local,'32');
        
//         if(!!!local) local = 'PreReleaseOut';
//         current = {
//             ...AllUrl[local],
//             // apiServer: 'aetsO.cafuc.edu.cn:8143', // opi 面试服务器地址
//             apiServer: 'opi.caeen.cn:8143', // opi 面试服务器地址
//         fileSave:'https://pre.examination.caeen.cn:20086/tfsFileSystem/filesDownloadSave?fileName=',
//             fileTwo:'https://pre.examination.caeen.cn:20087/',    
//             apiId:'63cdfb8a-03ef-44ae-8da6-d329f505deb8',
//            apiToken:'07157124-7044-4009-85fd-36dc6b1ef317',
//            auth:'800',
//         // eyeAuthorize:"172.16.14.40$&6IIIF4QTAHVHLBXB$&2",
// 		eyeAuthorize:"aetsnew.cafuc.edu.cn$&8273$&6IIIF4QTAHVHLBXB$&2",
//         //apiServer:'opi.caeen.cn:8143',
//         }
        
//         return current;
//     },
//     pro() {
//         const AllUrl = {  // test1 110 test2 111  PreReleaseIn 预发布内网 PreReleaseOut 预发布 外网  FormalIn 正式内网 FormalOut 正式外网
//             test1:{
//                 BaseUrl:'http://192.168.1.110:5001',
//                 fileUrl:'http://192.168.1.110:5004/filesDownload?filePath=',
//                 uploadUrl:'http://125.64.74.28:18100/tfsFileSystem/uploadFile',
//                 webSocketUrl:'ws://192.168.1.110:5001',
//                 uploadUrl_n:'https://192.168.1.110:15004',        
//             },
//             test2:{
//                 BaseUrl: 'https://192.168.1.111:15001',
//                 fileUrl: 'https://pre.examination.caeen.cn:20086/tfsFileSystem/filesDownload?fileName=',
//                 uploadUrl: 'https://pre.examination.caeen.cn:20086/tfsFileSystem/uploadFile',
//                 webSocketUrl: 'ws://192.168.1.111:15001',
//                 uploadUrl_n:'https://192.168.1.111:15004',
//             },
//             PreReleaseIn:{
//                 BaseUrl: 'https://pre.gateway.vpn.caeen.cn',
//                 fileUrl: 'https://pre.examination.caeen.cn:20086/tfsFileSystem/filesDownload?fileName=',
//                 uploadUrl: 'https://pre.examination.caeen.cn:20086/tfsFileSystem/uploadFile',
//                 webSocketUrl: 'ws://pre.gateway.vpn.caeen.cn',
//                 uploadUrl_n:'https://pre.fileupload.vpn.caeen.cn',
//             },
//             PreReleaseOut:{
//                 BaseUrl: 'https://pre.examination.caeen.cn:20084',
//                 fileUrl: 'https://pre.examination.caeen.cn:20086/tfsFileSystem/filesDownload?fileName=',
//                 uploadUrl: 'https://pre.examination.caeen.cn:20086/tfsFileSystem/uploadFile',
//                 webSocketUrl: 'ws://pre.examination.caeen.cn:20084',
//                 uploadUrl_n:'https://pre.examination.caeen.cn:20085',
//             },
//             FormalIn:{
//                 BaseUrl: 'https://aetsm.cafuc.edu.cn:10181',
//                 fileUrl: 'https://aetsnew.cafuc.edu.cn:10086/tfsFileSystem/filesDownload?fileName=',
//                 uploadUrl: 'https://aetsnew.cafuc.edu.cn:10086/tfsFileSystem/uploadFile',
//                 webSocketUrl: 'ws://aetsm.cafuc.edu.cn:10181',
//                 uploadUrl_n:'https://aetsnew.cafuc.edu.cn:10085',
//             },
//             FormalOut:{
//                 BaseUrl: 'https://gateway.vpn.caeen.cn:1443',
//                 fileUrl: 'https://tfsupload.vpn.caeen.cn:1443/tfsFileSystem/filesDownload?fileName=',
//                 uploadUrl: 'https://tfsupload.vpn.caeen.cn:1443/tfsFileSystem/uploadFile',
//                 webSocketUrl: 'ws://gateway.vpn.caeen.cn:1443',
//                 uploadUrl_n:'https://fileupload.vpn.caeen.cn:1443',
//             },
//         }
        
//         function GetQueryString(name){
//             var url = window.location.href.split('?')[1];
//             let localType = new URLSearchParams(url).get(name);
//             return localType;
//         }
//         console.log(window.location.href,123123123);
//         let current = {}
//         let local = '';
//         if(!!GetQueryString('localType')){
//             local = GetQueryString('localType');
//             sessionStorage.setItem('localType',local);
//         }else{
//             local = sessionStorage.getItem('localType');
//         }
//         console.log(local,'32');
        
//         if(!!!local) local = 'test2';
//         local = 'FormalIn';
//         current = {
//             ...AllUrl[local],
//                 // apiServer: 'aetsO.cafuc.edu.cn:8143', // opi 面试服务器地址
//                 apiServer: 'opi.caeen.cn:8143', // opi 面试服务器地址
//         fileSave:'https://aetsnew.cafuc.edu.cn:10086/tfsFileSystem/filesDownloadSave?fileName=',
//             fileTwo:'https://aetsM.cafuc.edu.cn:10184/',
//                 apiId:'63cdfb8a-03ef-44ae-8da6-d329f505deb8',
//            apiToken:'07157124-7044-4009-85fd-36dc6b1ef317',
//            auth:'800',
//           // eyeAuthorize:"face.caeen.cn$&6IIIF4QTAHVHLBXB$&2",
//         //    eyeAuthorize:"172.16.14.40$&6IIIF4QTAHVHLBXB$&2",
//            eyeAuthorize:"aetsnew.cafuc.edu.cn$&8273$&6IIIF4QTAHVHLBXB$&2",
//         }
        
//         return current;
//     }
// }

// export default urls[env]()