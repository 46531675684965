/*
 * @description  : 
 * @Author       : Junfeng Fu
 * @Date         : 2022-02-11 10:58:12
 * @LastEditors  : Junfeng Fu
 * @LastEditTime : 2023-03-06 11:45:21
 * @LastEditors: 6Ting
 * @LastEditTime: 2022-02-15 14:41:57
 */
import examinationApi from '@/AjaxApi/examinationApi';
import {message} from 'antd' 

export const getOldCouseCatlogById = async (props)=>{
    let res = await examinationApi.getOldCouseCatlogById({id:sessionStorage.curriculum,type:sessionStorage.ExamType=="first"?"1":"2",licenseType:sessionStorage.licenseType})
    props.ExaminationStore.setItem({key:"menu",value:res.data || []})
    props.ExaminationStore.setItem({key:"MenuList",value:res.data[0].childList || []})
    sessionStorage.setItem('targetceng',res.data[0].ceng);
}

/**
 * 上一题操作
 * @param {*} props 
 * @param {*} type 
 * @param {*} cb 
 */
export const previous = (props,cb)=>{
    let item = getCurNodeData(props,1);
    let IndexArray=JSON.parse(JSON.stringify(props.ExaminationStore.indexArray));
    let curIndex=IndexArray[IndexArray.length-1];
    if(curIndex==0){
        //当前为第一题，则跳到上一个章节，先做章节判断
        IndexArray.pop();
        let verifyIsFirst=IndexArray.filter(item=>item>0);
        if(verifyIsFirst.length>0){
            //如果存在大于0的节点，则不是初始节点
            let tempArr=previousIndexArr(IndexArray);
            props.ExaminationStore.setItem({key:"questionList",value:[]})
            //跳转到上一个childList
            props.ExaminationStore.setItem({key:"indexArray",value:tempArr});
            props.history.push('/Examination/explain')
        }else{
            message.warning("已经是第一题!")
            // alert('已经是第一题')
        }
    }else{
        //如当前不是第一题，则跳上一题
        curIndex--;
        IndexArray[IndexArray.length-1]=curIndex;
        props.ExaminationStore.setItem({key:"indexArray",value:IndexArray});
        cb&&cb();
    }
}

/**
 * 上一题回溯索引节点
 * @param {*} indexArr 
 * @returns 
 */
const previousIndexArr=(indexArr)=>{
    let i=indexArr[indexArr.length-1];
    if(i==0){
        indexArr.pop()
        return previousIndexArr(indexArr)
    }else{
        i--;
        indexArr[indexArr.length-1]=i;
        return indexArr;
    }
}


/**
 * 下一步跳转操作
 * @param {*} props 
 * @param {*} type  当前节点类型：0章节  1试题
 */

 export const nextForExam = async (props,type,cb) =>{
    let item = getCurNodeData(props,type);
    if(type==0){
        //章节
        updateIndexArr(props)

    }else{
        //试题
        let questions=props.ExaminationStore.questionList;
        let curIndex=props.ExaminationStore.indexArray[props.ExaminationStore.indexArray.length-1];
        if(questions.length-1>curIndex){
            // 如果还存在下一题
            curIndex++;
            updateIndexArr(props,curIndex,1)
            cb&&cb();
        }else{
            if(props.ExaminationStore.indexArray[1]   >= props.ExaminationStore.menu[0].childList.length -1){
                 //完成
                 heartBeat(props,false)
                await examinationApi.endTest()
                 props.history.push('/Examination/scoreTable')
            }else{
                props.ExaminationStore.setItem({key:"questionList",value:[]})
                //不存在下一题了则继续检索childList
                updateIndexArr(props,0,2);
                updateIndexArr(props,(props.ExaminationStore.indexArray[props.ExaminationStore.indexArray.length-1]+1),1);
                props.history.push('/Examination/explain')
            }

        }
    }
}



/**
 * 下一步跳转操作
 * @param {*} props 
 * @param {*} type  当前节点类型：0章节  1试题
 */

export const next = (props,type,cb) =>{
    let item = getCurNodeData(props,type);
    if(type==0){
        //章节
        updateIndexArr(props)
        if(item.questionList&&item.questionList.length>0){
            //如果当前章节存在试题，则处理试题
            props.ExaminationStore.setItem({key:"questionList",value:item.questionList})
            let questionTempId = item.questionList[0].quesTempId
            let pathMap = props.ExaminationStore.pathMap 
            let c  = pathMap.find((el)=>{
                return el.questionTempId == questionTempId
            })
            props.history.push(c.path)
        }else if(item.childList&&item.childList.length>0){
            //如果当前章节不存在试题，则下级章节
            if(cb){
                cb();
            }else{
                props.history.push('/Examination/explain')
            }
        }
    }else{
        //试题
        let questions=props.ExaminationStore.questionList;
        let curIndex=props.ExaminationStore.indexArray[props.ExaminationStore.indexArray.length-1];
        if(questions.length-1>curIndex){
            // 如果还存在下一题
            curIndex++;
            // let questionTempId = item.questionList[curIndex].quesTempId
            // let pathMap = props.ExaminationStore.pathMap 
            // let c  = pathMap.find((el)=>{
            //     return el.questionTempId == questionTempId
            // })
            updateIndexArr(props,curIndex,1)
            // props.history.push(c.path)
            cb&&cb();
        }else{
            let questionList = props.ExaminationStore.questionList
            props.ExaminationStore.setItem({key:"questionList",value:[]})
            //不存在下一题了则继续检索childList
            if(item.childList&&item.childList.length>0){
                //如果本级还存在下级childList
                updateIndexArr(props,0,2);
                next(props,0)
            }else{
                //如果本节点是最终节点
                //逆向查找节点
                
                let res=reviewNodeData(props)

                if(res=='Finish'){
                    //完成
                    // heartBeat(props,false)
                    // examinationApi.endTest()
                    // this.props.history.push('/Examination/scoreTable')
                    props.ExaminationStore.setItem({key:"questionList",value:questionList})
                    message.warning("已经是最后一题!")
                }else{
                    //跳转到下一个childList
                    props.ExaminationStore.setItem({key:"indexArray",value:res});
                    props.history.push('/Examination/explain')
                }
            }
        }
    }
}

/**
 * 逆向查找节点兄弟元素
 * @param {*} props 
 * @param {*} tempIndexArray 
 * @returns 
 */
const reviewNodeData=(props,tempIndexArray=null)=>{


    // debugger;

    let indexArray = tempIndexArray||JSON.parse(JSON.stringify(props.ExaminationStore.indexArray));
    indexArray.pop();
    if(indexArray.length==1){
        //主节点
        if(indexArray[0]==props.ExaminationStore.menu.length-1){
            //完成
            return 'Finish'
        }else{
            indexArray[0]=indexArray[0]+1;
            return indexArray;
        }
    }else{
        //子节点
        let i=indexArray[indexArray.length-1]+1;
        indexArray.pop()
        indexArray.push(i)
        let tempItem=getCurNodeData(props,0,indexArray);
        if(!tempItem){
            return reviewNodeData(props,indexArray);
        }else{
            return indexArray;
        }
    }
}

/**
 * 更新索引数组
 * @param {*} n 
 * @param {*} operate 0新加一个，1修改一个，2删除一个 
 */
const updateIndexArr=(props,n=0,operate=0)=>{
    let indexArray = JSON.parse(JSON.stringify(props.ExaminationStore.indexArray)) 
    if(operate>0){
        indexArray.pop();
    }
    if(operate<2){
        indexArray.push(n);
    }    
    props.ExaminationStore.setItem({key:"indexArray",value:indexArray});
}



/**
 * 获取当前节点数据
 * type 节点类型：0章节  1试题
 */
 export const getCurNodeData=(props,type=0,indexArr=null)=>{
    let item =[]
    let tempIndexArr=indexArr||props.ExaminationStore.indexArray;
    // console.log('tempIndexArrtempIndexArr',tempIndexArr);
    // debugger    
    for (let i = 0; i < tempIndexArr.length ; i++) {
        if(i==0){
            item=props.ExaminationStore.menu[tempIndexArr[0]]
        }else if(i==tempIndexArr.length-1 && type==1){
            // item=item.questionList;

        }else{
            item=item.childList[tempIndexArr[i]]
        }
    }
    return item
}


// export const next = (props) =>{
//     if(!props.ExaminationStore.getQuestionList.questionList){
//         let indexArray = JSON.parse(JSON.stringify(props.ExaminationStore.indexArray)) 
//         indexArray.push(0)
//         props.ExaminationStore.setItem({key:"indexArray",value:indexArray})
//     }else{
//         let indexArray = JSON.parse(JSON.stringify(props.ExaminationStore.indexArray)) 
//         indexArray.push(0)
//         let questionList = props.ExaminationStore.getQuestionList.questionList
//         console.log("questionList",questionList);
//         props.ExaminationStore.setItem({key:"indexArray",value:indexArray})
//         props.ExaminationStore.setItem({key:"questionList",value:questionList})
           
//         let questionTempId = questionList[0].quesTempId
//         let pathMap = props.ExaminationStore.pathMap 
//         let c  = pathMap.find((el)=>{
//             return el.questionTempId == questionTempId
//         })
//         console.log("跳转路径:",c.path);
//         props.history.push(c.path)
//     }
//     console.log(props.ExaminationStore.getQuestionList);
// }

export const changePath = (props)=>{
    // console.log(22222222);
    let questionTempId = props.ExaminationStore.questionList[0].quesTempId
    let pathMap = props.ExaminationStore.pathMap 
    let c  = pathMap.find((el)=>{
        return el.questionTempId == questionTempId
    })
    console.log("跳转路径:",c.path);
    props.history.push(c.path)
}

//添加学习记录
export const addTbStudyLog = async(props)=>{
    return new Promise( async (resolve, reject) => {
        let targetId =  getCurNodeData(props,1).id
    
        let addTbStudyLogId = sessionStorage.getItem('addTbStudyLogId')
        if(!addTbStudyLogId){
            
            sessionStorage.setItem("chapterStartTime",Date.parse(new Date()))
            sessionStorage.setItem("addTbStudyLogId",targetId)
            resolve('init')
            return
        } 
        const dateRes=await examinationApi.getSevDate();
        // let chapterStudyTime = Date.parse(new Date()) - sessionStorage.getItem('chapterStartTime')
        let chapterStudyTime = Date.parse(dateRes.data) - sessionStorage.getItem('newChapterStartTime');
        
        // 挂机时间
        // let suspendTime = 0
        // let chapterSuspendTime = sessionStorage.getItem('chapterSuspendTime')
        // let chapterSuspendTimeEnd = sessionStorage.getItem('chapterSuspendTimeEnd') || Date.parse(new Date())
        // if(chapterSuspendTime ){
        //     suspendTime = chapterSuspendTimeEnd + '' - chapterSuspendTime
        // } 

        sessionStorage.setItem("chapterStartTime",Date.parse(new Date()))
        sessionStorage.setItem("addTbStudyLogId",targetId)
        // sessionStorage.removeItem("chapterSuspendTime")
        // sessionStorage.removeItem("chapterSuspendTimeEnd")

        if(chapterStudyTime>1000){
             //学习时长不足1s的记录不提交
            let info ={
                trainClassId:sessionStorage.getItem('trainClassId') || '',
                courseId: sessionStorage.curriculum, //课程ID,
                courseName: props.ExaminationStore.menu[0] &&  props.ExaminationStore.menu[0].tbCourse.sourceName,
                sectionAllName: "",
                sectionId: getCurNodeData(props,1).id,
                sectionName: "",
                seatMealCourseId: "abc",
                uuid: sessionStorage.getItem('uuid'),
                // chapterStudyTime:(Math.round(((chapterStudyTime - suspendTime)/1000))*1000)
                chapterStudyTime:(Math.round(((chapterStudyTime)/1000))*1000),
            }
            let res = await examinationApi.addTbStudyLog(info)
        }
        resolve()
    })
}
//发送心跳
let time
export const heartBeat = async(props ,isOpen=true ) => {
     if(isOpen){
         time = setInterval(async()=>{
             let CurrentChapter=getCurNodeData(props,1);
             let sectionId = CurrentChapter.id
            //  let res = await examinationApi.heartBeat(sectionId)
         },60000)
     }else{
         clearInterval(time)
     }
}



