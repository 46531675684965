import Ajax from '../js/axios';
import AllUrl from '../js/util'

const service = '/exam-service';
const service_1 = '/itemBank-service';
const service_2 = '/manage-service';
const service_3 = '/pay-signup-service';

// const service = '';
// const service_1 = '';
// const service_2 = '';

class ajaxApi {
    // hardwareBindingCheckhave 
    static async hardwareBindingCheckhave (data) {
        let info = {
            url: service_2 + '/examinatin/hardwareBindingCheckhave',
            type: 'post',
            data
        }
        let data_ = await Ajax(info);
        return data_;
    }
    static async GetQuestion (data) {
        let info = {
            url: service_1 + '/getQuestion/getQuestion',
            type: 'post',
            data
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async IsExist (data) {
        let info = {
            url: service + '/addTestRecord/isExist',
            type: 'post',
            data
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async AddExaminationRecord (data) {
        let info = {
            url: service + '/addTestRecord/addExaminationRecord',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async AddAnswerRecord (data) {
        let info = {
            url: service + '/addTestRecord/addAnswerRecord',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async UploadFile (data) {
        let info = {
            url: AllUrl.uploadUrl,
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static GetWebSocketUrl () {
        return AllUrl.BaseUrl + service + service;
    }

    static async AddUserToQueue (data) {
        let info = {
            url: service + '/addTestRecord/addUserToQueue',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async GetOneUserInQueue (data) {
        let info = {
            url: service + '/addTestRecord/getOneUserInQueue',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async SendToAll (data) {
        // let str = '';
        // for (const i in data) {
        //     str += i + '='  + data[i] + '&';            
        // }
        let info = {
            url: service + '/useWebsocket/sendToAll',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async SendToOne (data) {
        let info = {
            url: service + '/useWebsocket/sendToOne',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async WebsockeHeart (data) {
        let info = {
            url: service + '/useWebsocket/websockeHeart',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    

    static async MockGetQuestion (data) {
        let info = {
            url: service_1 + '/simulationBank/getQuestion',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async FindSysDictByDictKey (data) {
        let info = {
            url: service_2 + '/sysDict/findSysDictByDictKey',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async AddChooseScore (data) {
        let info = {
            url: service + '/addTestRecord/addChooseScore',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async CheckTesterLogin (data) {
        let info = {
            url: service_3 + '/examineeArrange/checkTesterLogin',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async CheckCanStartTest (data) {
        let info = {
            url: service + '/TestWorker/checkCanStartTest',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async AddLittleRecord (data) { // 添加小题 记录
        let info = {
            url: service_1 + '/getQuestion/addLittleRecord',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async DelUserLittleRecord (data) { // 考试结束 删除记录
        let info = {
            url: service_1 + '/getQuestion/delUserLittleRecord',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async AddTeacherInfo (data) { // 考生 添加考官记录
        let info = {
            url: service + '/addTestRecord/addTeacherInfo',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async GetOPIQuestion (data) { // 考官 获取考生考试试题
        let info = {
            url: service + '/addTestRecord/getOPIQuestion',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async RoomArrangeGetRoom (data) { // 考官 获取f房间
        let info = {
            url: '/exam-service/RoomArrange/getRoom',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async RoomArrangeUpdateRoom (data) { // 考官 更新房间信息
        let info = {
            url: '/exam-service/RoomArrange/updateRoom',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async GetLostUserInQueue (data) { // 考官 获取 队列人数信息
        let info = {
            url: '/exam-service/addTestRecord/getLostUserInQueue',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async GetOPIrecord (data) { // 考官 获取 已面试人数
        let info = {
            url: '/exam-service/OPIrecord/getOPIrecord',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }
    static async GetOpiTime(data){ //获取考生 opi时间
        let info = {
            url:'/exam-service/RuleForm/getOpiTime',
            type:'post',
            data
        }
        let data_ = await Ajax(info);
        return data_;
    }
    static async AddOPIrecord (data) { // 开始面试 添加记录
        let info = {
            url: '/exam-service/OPIrecord/addOPIrecord',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async UpdateOPIrecord (data) { // 考官 更新 记录
        let info = {
            url: '/exam-service/OPIrecord/updateOPIrecord',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }
    

    static async GetExamNumber (data) { // 查询 考试期数
        let info = {
            url: '/exam-service/OPIrecord/getExamNumber',
            type: 'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }

    static async AudioSynthesis(data){ // 音频 合成
        let info = {
            url:service + '/addTestRecord/addVideoToOne',
            type:'post',
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }
    static GetParameter = async(data)=>{
        let info = {
            type:'post',
            url:"/manage-service/sysDict/findSysDictByDictKey",
            data,
        }
        let data_ = await Ajax(info);
        return data_;
    }
    static GetParameterOne = async(data)=>{
        return Ajax({
            type:'post',
            url:"/manage-service/sysDict/getTreeDictByFirstLevelDictKey",
            data,
        })
    }
    static FreshTime = async(data)=>{ // 考生 在队列中的心跳 
        return Ajax({
            type:'post',
            url:"/exam-service/addTestRecord/freshTime",
            data,
        })
    }



    // 中断考试全部
    static inspectExamPlan = async(data)=>{
        return Ajax({
            type:'post',
            url:"/exam-service/InspectTest/inspectExamPlan",
            data,
        })
    }

    // 中断考试单个
    static breakExamForOne = async(data)=>{
        return Ajax({
            type:'post',
            url:"/exam-service/InspectTest/inspectUser",
            data,
        })
    }

    // 中断考试身份比对
    static passwordValidate = async(data)=>{
        return Ajax({
            type:'post',
            url:"/exam-service/InspectTest/passwordValidate",
            data,
        })
    }

    
}
export default ajaxApi;